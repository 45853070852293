import "./LoginPage.scss";

import { Divider, message } from "antd";
import React, { useState } from "react";
import { BsMicrosoft } from "react-icons/bs";
import { useHistory } from "react-router";

import settings from "../../../config/AppSettings/SettingsFactory";
import { RoutesPaths } from "../../../constants/RoutePaths";
import { authService } from "../../../services/AuthService";
import { ValidateEmail } from "../../../utils/EmailUtils";
import SupervisionTitle from "./SupervisionTitle";

type Props = {};

const LoginPage = (props: Props) => {
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const handleFormLogin = async () => {
        if (!email) {
            message.error("Email can't be empty");
            return;
        }

        if (!password) {
            message.error("Password can't be empty");
            return;
        }

        if (!ValidateEmail(email)) {
            message.error("Please provide a valid email");
            return;
        }

        try {
            setLoading(true);
            await authService.Login({
                email,
                password,
            });

            setLoading(false);
            history.push(RoutesPaths.Supervision.Campaigns);
        } catch (error) {
            message.error("Invalid credentials");
        }
    };

    return (
        <div className="login-wrapper">
            <SupervisionTitle />
            <div className="login">
                <h1>Login</h1>
                {/*<form
                    action={`${settings.serverUrl}/oauth/external-login?provider=Microsoft&returnUrl=/home`}
                    method="POST">
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        type="submit"
                        className="btn btn-primary btn-block btn-large">
                        <BsMicrosoft style={{ marginRight: "10px" }} />
                        Microsoft Login
                    </button>
                </form> 
                <Divider> OR </Divider>*/}
                <form method="post">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                        onClick={handleFormLogin}
                        type="button"
                        className="btn btn-primary btn-block btn-large">
                        {loading ? "Loading..." : "Let me in."}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
